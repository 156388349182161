.dialog {
  padding: 20px !important;
  width: 527px !important;
  height: 218px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.biggerText {
  font-weight: 500 !important;
  font-size: 20px !important;
  margin-bottom: 5px !important;
}

.smallText {
  font-weight: normal !important;
  font-size: 14px !important;
  margin-bottom: 25px !important;
  color: rgba(0, 0, 0, 0.6);
}

.mediumText {
  color: #1B181C !important;
  font-size: 16px !important;
  margin-bottom: 5px !important;
}

.inputValue {
  width: 100% !important;
}

.cancelButton {
  background: transparent !important;
  border-radius: 4px !important;
  padding: 5px 20px !important;
  color: #A0001F !important;
  margin-right: 10px !important;
}

.authorizeButton {
  background: #E4282C !important;
  border-radius: 4px !important;
  padding: 5px 20px !important;
  color: #ffffff !important;
}

.modalFooter {
  display: flex !important;
  justify-content: flex-end;
}
