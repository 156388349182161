.paymentInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.paymentInfoTitleItem {
  flex-direction: row-reverse;
  display: flex;
  color: gray;
}

.titleStyle {
  font-size: 34px !important;
  font-weight: 700 !important;
  letter-spacing: -0.04em !important;
}

.divider {
  display: flex;
  margin-top: 30px;
  margin-left: -22px;
  margin-right: -22px;
  word-break: break-all;
}

.contentStyle {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.mobileContentStyle {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.biggerTextStyle {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.biggerTextStyleInfo {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.marginStyle {
  margin-top: 2em;
}

.buttonStyle {
  height: 18vh;
  width: 100%;
}

.primaryColor {
  color: #A0001F;
}

.warningBoxOfFiredEmployees {
  background-color: #df910010;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin: 16px 0 16px 0;
  padding: 8px 16px;
  border: 1px solid #df9100;
  border-radius: 8px;
}

.warningIcon {
  fill: #df9100;
}

.warningMessage {
  font-weight: 500;
}

.tableRowWithFiredEmployee {
  background-color: #e5441720;
}

.tableCell {
  height: 69px;
}

.dropzoneStyle {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  background-color: #f1edf3;
  border-radius: 12px;
  padding: 15px;
  align-items: center;
}

.buttonTextStyle {
  color: #A0001F;
  display: flex;
}

.fileAvatarStyle {
  color: #A0001F;
  width: 58px;
}

.fileTextStyle {
  font-size: 20px;
  line-height: 24px;
  color: #A0001F;
}

.fileButtonStyle {
  color: #A0001F;
  align-self: center;
}

.textFieldStyle {
  border-radius: 4px;
}

.hidden {
  display: none;
}

.activeProgressItem {
  background: #A0001F;
  border-radius: 94px;
  display: flex;
  width: 40px;
  height: 40px;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-right: 8px;
}

.progressItemTitle {
  font-size: 12px;
  color: #808080;
  line-height: 14px;
}

.divider {
  border: 1px solid #e0e0e0;
  margin: 25px 0px;
}

.horizontalLine {
  border-bottom: 2px solid #A0001F;
  width: 40px;
  margin: 0 8px;
}

.progressIcon {
  font-size: 18px;
}

@media (max-width: 520px) {
  .titleStyle {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }

  .card {
    padding: 20px;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    flex-wrap: wrap;
  }

  .card:last-child {
    margin-bottom: 90px;
  }

  .text {
    font-size: 18px;
    color: #000000;
    margin-bottom: 8px;
  }

  .text:last-child {
    margin-top: 20px;
  }

  .statusCard {
    flex: none;
    order: 2;
    flex-grow: 0;
    background: #eaeef8;
    border-radius: 15px;
    padding: 5px 10px;
    color: #2d54b9;
  }

  .redStatusCard {
    flex: none;
    order: 2;
    flex-grow: 0;
    background: #fcece8;
    border-radius: 15px;
    padding: 5px 10px;
    color: #E4282C;
  }

  .statusCardIcon {
    margin-right: 6px;
    position: relative;
    top: 7px;
    width: 20px;
  }

  .continueButtonContainer {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 10px 20px;
  }

  .continueButton {
    background: #A0001F;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 50px;
    color: #fff;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
  }

  .continueButtonLoader {
    width: 16px;
    height: 16px;
    color: #fff;
  }
}
