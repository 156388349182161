.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 30px;
  margin-bottom: 20px;
  align-items: baseline;
}

.filter {
  width: 23%;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.dateLabel {
  font-size: 16px;
  color: #808080;
}

.dateError {
  color: #f44336;
  font-size: 12px;
}
