* {
  font-family: 'DM Sans', sans-serif !important;
}

:root {
  --purple: #A0001F;
  --purpleDigital: #E4282C;
  --orange: #A0001F;
  --orangeDigital: #E4282C;
  --black: #000000;
  --gray: #828282;
  --white: #FFFFFF;
  --backGroundFormField: #F1F1F1;

  --xsmallText: 12px;
  --smallText: 14px;
  --mediumText: 18px;
  --largeText: 24px;
  --xlargeText: 34px;
}

#simple-tabpanel-0 > div,
#simple-tabpanel-1 > div {
  padding: 0px;
}

header {
  background-color: #fff !important;
}

@media(max-width: 520px) {
  #root > div > div {
    display: initial;
  }
}
