.container {
  padding: 23px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
}

.titleContainer {
  margin-bottom: 8px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem !important;
  line-height: 1.688rem;
  letter-spacing: -0.04em;
  color: rgba(0, 0, 0, 0.87);
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.125rem;
  color: rgba(0, 0, 0, 0.6);
}

.text {
  font-size: 0.75rem !important;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

.buttonContainer {
  margin-top: 26px;
}

.button {
  margin-top: 10px;
  height: 2.25rem;
  padding: 10px 16px;
  color: #fff;
  background-color: #E4282C !important;
  border-radius: 4px;
}

.button p {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 1rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
}
.closeButton {
  margin-top: 10px;
  border-radius: 6px;
  color: #A0001F;
  padding: 6px 20px;
  margin-right: 10px;
  font-weight: 500;
  white-space: nowrap;
}
